<template>
  <v-app-bar
      app
      clipped-left
    >
      <v-app-bar-nav-icon @click="toggle" />
      <div v-if="!isMobile" :class="$style.linkWrapper" class="d-flex align-center">
        <router-link to="/" :class="$style.link">
          <div :class="$style.title">UA Integrations</div>
        </router-link>
      </div>
      <ProjectMenu />

      <v-spacer></v-spacer>

      <UserPanel />
    </v-app-bar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import UserPanel from '../UserPanel/UserPanel.vue';
import ProjectMenu from '../ProjectMenu/ProjectMenu.vue';

export default {
  name: 'ProjectHeader',

  components: {
    UserPanel,
    ProjectMenu,
  },

  computed: {
    ...mapGetters([
      'isMobile',
    ]),
  },

  methods: {
    ...mapActions('sidebar', [
      'toggle',
    ]),
  },
};
</script>

<style lang="less" module>
.linkWrapper {
  margin-left: 12px;
}
.link {
  text-decoration: none;
}
.title {
  font-size: 20px;
  color: #fff;
}
</style>
