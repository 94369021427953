<template>
  <div :class="$style.wrapper">
    <v-layout row :class="$style.container">
      <v-card
        v-for="project in projects"
        :key="project.id"
        :color="getCardColor(project)"
        class="mx-auto"
        max-width="300"
        :class="$style.card"
        @click="onProjectClick(project)"
      >
        <ProjectImage :backgrounds="getCardBackground(project)" />

        <v-card-title :class="$style.projectName" v-html="project.descr"></v-card-title>
      </v-card>
    </v-layout>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ProjectImage from './ProjectImage.vue';

export default {
  name: 'ProjectList',

  components: {
    ProjectImage,
  },

  props: {
    personal: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {};
  },

  computed: {
    ...mapGetters('project', [
      'currentProject',
      'allProjects',
      'myProjects',
    ]),

    ...mapGetters([
      'isAuthenticated',
    ]),

    projects() {
      return this.personal ? this.myProjects : this.allProjects;
    },
  },

  methods: {
    ...mapActions('project', [
      'selectProject',
    ]),

    onProjectClick(project) {
      const isAuthenticated = this.isAuthenticated({ projectId: project.id });

      if (isAuthenticated) {
        this.selectProject({ project });
      } else {
        this.selectProject({ project: {} });
        this.$router.push({ path: `/login/${project.id}` });
      }
    },

    getCardColor(project) {
      return this.myProjects.find((p) => p.id === project.id) ? '#006064' : '';
    },

    getCardBackground(project) {
      const { url_logo: backgrounds } = project;
      return backgrounds.split(',');
    },
  },
};
</script>

<style lang="less" module>
.wrapper {
  padding: 24px;
}

.container {
  gap: 20px;
}

.card {
  cursor: pointer;
  margin-bottom: 20px;
  margin-right: 20px;
}

.projectName.projectName {
  word-break: break-word;
}
</style>
