import Vue from 'vue';
import VueRouter from 'vue-router';
// import store from '../store';
import HomeView from '../views/HomeView.vue';
import LoginView from '../views/LoginView.vue';
import SettingsView from '../views/SettingsView.vue';
import ManageView from '../views/ManageView.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/manage',
    name: 'manage',
    component: ManageView,
  },
  {
    path: '/login/:id',
    name: 'login',
    component: LoginView,
  },
  {
    path: '/settings',
    name: 'settings',
    component: SettingsView,
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
  },
  {
    path: '/license',
    name: 'license',
    component: () => import(/* webpackChunkName: "license" */ '../views/LicenseView.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
