import { render, staticRenderFns } from "./ProjectHeader.vue?vue&type=template&id=16361b0a&"
import script from "./ProjectHeader.vue?vue&type=script&lang=js&"
export * from "./ProjectHeader.vue?vue&type=script&lang=js&"
import style0 from "./ProjectHeader.vue?vue&type=style&index=0&lang=less&module=true&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAppBar,VAppBarNavIcon,VSpacer})
