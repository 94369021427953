import { fetchProjects } from '@/actions';
import LocalData from '@/utils/localData';
import constants from '@/configs/constants';
import isEmptyObject from '@/utils/isEmptyObject';
import randomNumber from '@/utils/randomNumber';

const Storage = new LocalData(constants.currentProjectStorageKey);

export default {
  namespaced: true,

  state: {
    allProjects: [],
    myProjects: [],
    project: Storage.getData(),
    projectFetched: false,
    menuItems: [],
  },

  getters: {
    projectFetched(state) {
      return state.projectFetched;
    },

    currentProject(state) {
      return state.project;
    },

    allProjects(state) {
      return state.allProjects;
    },

    myProjects(state) {
      return state.myProjects;
    },

    getProject(state) {
      return ({ projectId }) => state.allProjects.find((project) => project.id === projectId);
    },

    menuItems(state) {
      return state.menuItems;
    },

    menuItemsByGroup(state) {
      return ({ groupName }) => state.menuItems.filter((item) => item.name_group === groupName);
    },

    background(state) {
      if (!state.project) {
        return '';
      }

      const { url_bacground: backgrounds } = state.project;

      if (!backgrounds) {
        return '';
      }

      const backgroundsParsed = backgrounds.split(',');
      const randomIndex = randomNumber(backgroundsParsed.length);

      return backgroundsParsed[randomIndex];
    },
  },

  mutations: {
    selectProject(state, { project }) {
      state.project = project;
    },

    saveProjects(state, { projects }) {
      state.allProjects = projects;
    },

    setMyProjects(state, { projects }) {
      state.myProjects = projects;
    },

    addMyProject(state, { project }) {
      state.myProjects.push(project);
    },

    removeMyProject(state, { projectId }) {
      state.myProjects = state.myProjects.filter((p) => p.id !== projectId);
    },

    setProjectFetched(state) {
      state.projectFetched = true;
    },

    setMenuItems(state, { menuItems }) {
      state.menuItems = menuItems;
    },
  },

  actions: {
    async fetchProjects({ commit, rootState }) {
      const {
        data: { cmd_result: projects },
      } = await fetchProjects();

      commit('saveProjects', { projects });

      const ids = Object.keys(rootState.tokens).map((id) => parseInt(id, 10));

      commit('setMyProjects', { projects: projects.filter((project) => ids.includes(project.id)) });
      commit('setProjectFetched');
    },

    selectProject({
      commit,
      dispatch,
      getters,
      rootGetters,
    }, {
      project,
    }) {
      commit('selectProject', { project });

      if (isEmptyObject(project)) {
        Storage.setData(project);

        return;
      }

      if (!getters.myProjects.find((p) => p.id === project.id)) {
        commit('addMyProject', { project });
      }

      if (!rootGetters['user/user']) {
        dispatch('user/authentificate', { projectId: getters.currentProject.id }, { root: true });
      }

      Storage.setData(project);
    },

    removeProject({ commit, getters, dispatch }, { projectId }) {
      commit('removeMyProject', { projectId });
      const [firstProject] = getters.myProjects;

      if (firstProject) {
        dispatch('selectProject', { project: firstProject });
      } else {
        commit('selectProject', { project: {} });
        Storage.setData({});
      }
    },

    setMenuItems({ commit }, { menuItems }) {
      commit('setMenuItems', { menuItems });
    },
  },
};
