<template>
  <v-img
    :src="background"
    height="200px"
  ></v-img>
</template>

<script>
import randomNumber from '@/utils/randomNumber';

const defaultImage = 'https://cdn.vuetifyjs.com/images/cards/sunshine.jpg';

export default {
  name: 'ProjectImage',

  props: {
    backgrounds: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      background: '',
    };
  },

  created() {
    this.getBackground(this.backgrounds);
  },

  methods: {
    async getBackground(backgrounds) {
      if (backgrounds.length === 0) {
        this.background = defaultImage;
        return;
      }

      const randomIndex = randomNumber(backgrounds.length);
      const background = backgrounds[randomIndex];

      if (!background) {
        const restBackgrounds = [...backgrounds];
        restBackgrounds.splice(randomIndex, 1);
        this.getBackground(restBackgrounds);
        return;
      }

      const img = new Image();
      img.onload = () => {
        this.background = background;
      };
      img.onerror = () => {
        const restBackgrounds = [...backgrounds];
        restBackgrounds.splice(randomIndex, 1);
        this.getBackground(restBackgrounds);
      };
      img.src = background;
    },
  },
};
</script>
