<template>
  <div :class="$style.wrapper">
    <v-divider
      vertical
      :class="$style.divider"
    ></v-divider>
    <v-menu
      v-if="isThereProjects"
      bottom
      left
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          text
          v-bind="attrs"
          v-on="on"
        >
          {{ projectName }}
          <v-icon>expand_more</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item
          v-for="project in myProjects"
          :key="project.id"
          ripple
          link
          @click="onMenuSelect(project)"
        >
          <v-list-item-title>{{ project.descr }}</v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item key="all" ripple to="/">
          <v-list-item-icon><v-icon>domain</v-icon></v-list-item-icon>
          <v-list-item-title>{{ $t('menu.all_projects') }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ProjectMenu',

  components: {
  },

  data() {
    return {
      unsubscribe: null,
      groups: [],
    };
  },

  computed: {
    ...mapGetters([
      'token',
    ]),

    ...mapGetters('project', [
      'myProjects',
      'currentProject',
    ]),

    isThereProjects() {
      return this.myProjects.length > 0;
    },

    projectName() {
      return this.currentProject.descr || this.$t('projects.choose_project');
    },
  },

  methods: {
    ...mapActions('project', [
      'selectProject',
    ]),

    onMenuSelect(project) {
      this.selectProject({ project });

      if (this.$route.name === 'login') {
        this.$router.push({ name: 'home' });
      }
    },
  },
};
</script>

<style lang="less" module>
.wrapper {
  display: flex;
  flex-direction: row;
}

.divider.divider.divider {
  margin: 0 12px;
  border-color: rgba(255, 255, 255, 0.36);
}
</style>
