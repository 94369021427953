import Vue from 'vue';
import Vuex from 'vuex';
import LocalData from '@/utils/localData';
import user from './modules/user';
import project from './modules/project';
import sidebar from './modules/sidebar';
import constants from '../configs/constants';

const Storage = new LocalData(constants.accessTokenStorageKey);

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    tokens: Storage.getData(),
    status: '',
    isMobile: false,
  },

  getters: {
    token(state) {
      return ({ projectId }) => state.tokens[projectId];
    },

    isMobile(state) {
      return state.isMobile;
    },

    isAuthenticated(state) {
      return ({ projectId }) => !!state.tokens[projectId];
    },

    authStatus(state) {
      return state.status;
    },
  },

  mutations: {
    updateToken(state, { projectId, token }) {
      state.tokens[projectId] = token;
    },

    deleteToken(state, { projectId }) {
      delete state.tokens[projectId];
    },

    setMobile(state, { isMobile }) {
      state.isMobile = isMobile;
    },
  },

  actions: {
    saveToken({ commit, state }, { projectId, token }) {
      commit('updateToken', { projectId, token });
      Storage.setData(state.tokens);
    },

    deleteToken({ commit, state }, { projectId }) {
      commit('deleteToken', { projectId });
      Storage.setData(state.tokens);
    },

    setMobile({ commit }, { isMobile }) {
      commit('setMobile', { isMobile });
    },
  },

  modules: {
    user,
    project,
    sidebar,
  },
});
