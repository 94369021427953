<template>
  <v-navigation-drawer
    :value="toggled"
    app
    clipped
    :permanent="!isMobile"
    :temporary="isMobile"
    :mini-variant.sync="mini"
    :class="$style.menuContainer"
    @input="onToggle"
  >
    <v-list
      dense
      nav
    >
      <v-list-item
        v-for="item in staticMenus"
        :key="item.name"
        link
        :to="item.link"
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ item.label }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <template v-if="projectSelected">
      <v-divider
      ></v-divider>
      <div v-for="menuItem in menu" :key="menuItem.id">
        <v-menu
          v-if="menuItem.groups"
          open-on-hover
          right
        >
          <template v-slot:activator="{ on, attrs }">
            <v-list-item
              v-bind="attrs"
              v-on="on"
            >
              <v-list-item-icon v-if="mini">
                <v-icon>{{ menuItem.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ menuItem.label }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <v-list v-if="mini" dense>
            <v-list-item
              v-for="group in menuItem.groups"
              :key="group.groupName"
              ripple
              link
              @click="onGroupClick(group)"
            >
              <v-list-item-icon>
                <v-icon>{{ group.icon || menuItem.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ group.groupName }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-list
          v-if="!mini"
          dense
          nav
        >
          <v-list-item
            v-for="groupItem in menuItem.groups"
            :key="groupItem.groupName"
            link
            @click="onGroupClick(groupItem)"
          >
            <v-list-item-icon>
              <v-icon>{{ groupItem.icon || menuItem.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ groupItem.groupName }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { fetchUserMenu } from '@/actions';
import isEmptyObject from '../../utils/isEmptyObject';

export default {
  name: 'SidebarMenu',

  data() {
    return {
      initialed: false,
      staticMenus: [
        {
          name: 'about',
          link: 'about',
          icon: 'home',
          label: this.$t('sidebar.about'),
        },
        {
          name: 'license',
          link: 'license',
          icon: 'assignment',
          label: this.$t('sidebar.license'),
        },
      ],
      menu: [
        {
          id: 1,
          label: this.$t('menu.open'),
          groups: [],
          icon: 'room_preferences',
        },
      ],
    };
  },

  computed: {
    ...mapGetters([
      'token',
      'isMobile',
      'isAuthenticated',
    ]),

    ...mapGetters('sidebar', [
      'toggled',
    ]),

    ...mapGetters('project', [
      'currentProject',
      'getProject',
      'projectFetched',
    ]),

    mini() {
      return this.isMobile ? false : this.toggled;
    },

    projectSelected() {
      return !isEmptyObject(this.currentProject);
    },
  },

  watch: {
    currentProject() {
      this.fetchMenu();
    },
  },

  created() {
    if (this.projectFetched) {
      this.fetchMenu();
    } else {
      this.unsubscribe = this.$subscribe('project/saveProjects', this.fetchMenu);
    }
  },

  beforeDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  },

  methods: {
    ...mapActions('project', [
      'setMenuItems',
    ]),

    ...mapActions('sidebar', [
      'toggle',
    ]),

    ...mapActions('user', [
      'logout',
    ]),

    async fetchMenu() {
      if (isEmptyObject(this.currentProject)
        || !this.isAuthenticated({ projectId: this.currentProject.id })) {
        this.setMenuItems({ menuItems: [] });
        this.resetMenu();

        return;
      }

      const { data: { cmd_result: menuItems, DETAIL_USER: user } } = await fetchUserMenu({
        url: this.currentProject.url_api_ios,
        token: this.token({ projectId: this.currentProject.id }),
      });

      if (Array.isArray(user) && user.length === 0) {
        this.setMenuItems({ menuItems: [] });
        this.resetMenu();
        await this.logout();
        this.$router.push({
          name: 'home',
        });
        return;
      }

      if (menuItems.length === 0 || !menuItems.some(Boolean)) {
        this.setMenuItems({ menuItems: [] });
        this.resetMenu();
        return;
      }

      this.setMenuItems({ menuItems });
      this.generateMenu(menuItems);
    },

    generateMenu(menuItems) {
      this.sortByMenuType(menuItems);
    },

    sortByMenuType(menuItems) {
      // Handle empty menu
      if (menuItems.length === 0) {
        this.resetMenu();

        return;
      }

      /* eslint-disable no-param-reassign */
      const itemsByMenuType = menuItems.reduce((menuGroups, item) => {
        const { menu_type: menuType = 1 } = item;

        if (!menuGroups[menuType]) {
          menuGroups[menuType] = [];
        }

        menuGroups[menuType].push(item);

        return menuGroups;
      }, {});
      /* eslint-enable no-param-reassign */

      Object.entries(itemsByMenuType).forEach(([key, value]) => {
        const menuItem = this.menu.find((item) => item.id === Number(key));

        menuItem.groups = this.sortByGroups(value);
      });
    },

    sortByGroups(menuItems) {
      /* eslint-disable no-param-reassign */
      return menuItems.reduce((groups, item) => {
        const { name_group: name } = item;

        if (!groups[name]) {
          groups[name] = {
            groupName: name,
            items: [],
          };
        }

        if (!groups[name].icon && item.icon) {
          groups[name].icon = item.icon;
        }

        groups[name].items.push(item);

        return groups;
      }, {});
      /* eslint-enable no-param-reassign */
    },

    resetMenu() {
      /* eslint-disable no-param-reassign */
      this.menu.forEach((menuItem) => {
        menuItem.groups = [];
      });
      /* eslint-enable no-param-reassign */
    },

    onGroupClick(group) {
      this.$router.push({
        name: 'manage',
        query: {
          groupName: group.groupName,
        },
      });
    },

    onToggle(value) {
      if (!this.initialed) {
        this.initialed = true;

        return;
      }

      this.toggle({ toggled: value });
    },
  },
};
</script>

<style lang="less" module>
.menuContainer :global(.v-navigation-drawer__content) {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */

  &::-webkit-scrollbar {
    display: none;  /* Safari and Chrome */
  }
}
</style>
