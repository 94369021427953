<template>
  <div>
    <div v-if="projectId && user" :class="$style.user">
      <template v-if="isMobile">
        <v-menu bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-list-item
              v-bind="attrs"
              v-on="on"
            >
              <v-list-item-content :class="$style.logout">
                <v-list-item-title>{{ user.login }}<v-icon>more_vert</v-icon></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <v-list dense>
            <v-list-item
              ripple
              link
              @click="onLogoutClick"
            >
              <v-list-item-title :class="$style.logout">
                <div>{{ $t('header.logout') }}</div>
                <v-icon size="16" :class="$style.logoutIcon">logout</v-icon>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-else>
        <div :class="$style.userName">
          {{ user.login }}
        </div>
        <v-btn text small @click="onLogoutClick">
          <div :class="$style.logout">
            <div>{{ $t('header.logout') }}</div>
            <v-icon size="16" :class="$style.logoutIcon">logout</v-icon>
          </div>
        </v-btn>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import isEmptyObject from '../../utils/isEmptyObject';

export default {
  name: 'UserPanel',

  data() {
    return {
      unsubscribe: null,
    };
  },

  computed: {
    ...mapGetters([
      'isMobile',
    ]),

    ...mapGetters('user', [
      'user',
    ]),

    ...mapGetters('project', [
      'projectFetched',
      'currentProject',
    ]),

    projectId() {
      return isEmptyObject(this.currentProject) ? null : this.currentProject.id;
    },
  },

  watch: {
    '$route.params.id': function () {
      this.checkProject();
    },
  },

  created() {
    this.checkProject();
  },

  methods: {
    ...mapActions('user', [
      'logout',
      'authentificate',
    ]),

    checkProject() {
      if (!this.projectId) {
        return;
      }

      if (this.projectFetched) {
        this.fetchUserData();
      } else {
        this.unsubscribe = this.$subscribe('project/saveProjects', this.fetchUserData);
      }
    },

    fetchUserData() {
      this.authentificate({ projectId: this.projectId });

      if (this.unsubscribe) {
        this.unsubscribe();
        this.unsubscribe = null;
      }
    },

    async onLogoutClick() {
      await this.logout();

      if ((isEmptyObject(this.currentProject) && this.$route.name !== 'home') || this.$route.name === 'manage') {
        this.$router.push({ name: 'home' });
      }
    },

    onLoginClick() {
      this.$router.push({ name: 'login' });
    },
  },
};
</script>

<style lang="less" module>
.user {
  display: flex;
  align-items: center;
}

.userName {
  margin-right: 8px;
}

.logout {
  display: flex;
  align-items: center;
}

.logoutIcon {
  margin-left: 6px;
}

.line {
  display: flex;
}
</style>
