import axios from 'axios';

export const login = async ({ url, payload }) => axios.post(url, payload);

export const fetchProjects = async () => axios.post('https://interlock.pp.ua/api/ios.php', {
  cmd: 'get_all_projects',
});

export const fetchUserMenu = async ({ url, token }) => axios.post(url, {
  token,
  cmd: 'get_my_menu',
});

export const fetchUserData = async ({ url, token }) => axios.post(url, {
  token,
  cmd: 'user_get_key',
});

export const logoutUser = async ({ url, token }) => axios.post(url, {
  token,
  cmd: 'user_user_del_key',
});

export const runCommand = async ({ url, token, commandName }) => axios.post(url, {
  token,
  cmd: 'run_cmd',
  name_cmd: commandName,
});

export const buttonStatusCheck = async ({ url, token, commandName }) => axios.post(url, {
  token,
  cmd: 'get_status_button',
  p_cmd_in: commandName,
});

export const backgroundHealthCheck = async ({ url }) => {
  const result = await axios.get(url);
  console.log(result);

  return true;
};
