export default class LocalData {
  constructor(localKey, defaultData = {}) {
    this.key = localKey;
    this.defaultData = defaultData;
  }

  getData() {
    try {
      const data = JSON.parse(localStorage.getItem(this.key));

      return data || this.defaultData;
    } catch (e) {
      console.error(e);
      localStorage.removeItem(this.key);

      return this.defaultData;
    }
  }

  setData(data) {
    localStorage.setItem(this.key, JSON.stringify(data));
  }
}
