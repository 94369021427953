<template>
  <v-container>
    <v-tabs v-model="tab" background-color="rgba(18, 18, 18, .4)">
      <v-tab
        v-for="tab in tabs"
        :key="tab.tabName"
      >
        {{ tab.tabName }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab"  :class="$style.tabs">
      <v-tab-item
        v-for="tab in tabs"
        :key="tab.tabName"
      >
        <v-card flat :class="$style.container">
          <CommandButton v-for="item in tab.items" :key="item.id_access" :item="item" />
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import CommandButton from '@/components/CommndButton/CommandButton.vue';

export default {
  name: 'ManageView',

  components: {
    CommandButton,
  },

  data() {
    return {
      tab: null,
    };
  },

  computed: {
    ...mapGetters('project', [
      'getProject',
      'projectFetched',
      'menuItemsByGroup',
    ]),

    menuItems() {
      const { groupName } = this.$route.query;

      return this.menuItemsByGroup({ groupName });
    },

    tabs() {
      /* eslint-disable no-param-reassign */
      return this.menuItems.reduce((tabs, menuItem) => {
        const { name_menu_tbot: tabName } = menuItem;

        if (!tabs[tabName]) {
          tabs[tabName] = {
            tabName,
            items: [],
          };
        }

        tabs[tabName].items.push(menuItem);

        return tabs;
      }, {});
      /* eslint-enable no-param-reassign */
    },
  },

  created() {

  },

  methods: {
  },
};
</script>

<style lang="less" module>
.tabs {
  &:global(.v-tabs-items.v-tabs-items) {
    background-color: rgba(18, 18, 18, .4);
  }
}

.item {
  cursor: pointer;
}

.container {
  padding: 16px 16px;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;

  &:global(.v-card) {
    background-color: transparent;
  }
}
</style>
