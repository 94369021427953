import {
  fetchUserData,
  logoutUser,
} from '@/actions';

export default {
  namespaced: true,
  state: {
    user: null,
  },
  getters: {
    user(state) {
      return state.user;
    },
  },
  mutations: {
    updateUser(state, { user }) {
      state.user = user;
    },
  },
  actions: {
    async authentificate({ dispatch, commit, rootGetters }, { projectId }) {
      try {
        const project = rootGetters['project/getProject']({ projectId });
        const {
          data: { DETAIL_USER: user },
        } = await fetchUserData({
          token: rootGetters.token({ projectId }),
          url: project.url_api_ios,
        });

        if (Array.isArray(user)) {
          commit('updateUser', { user });
          dispatch('saveToken', '', { root: true });
          return;
        }

        dispatch('saveUser', { user });
      } catch (e) {
        console.error(e);
      }
    },

    async logout({
      commit,
      dispatch,
      rootGetters,
    }, {
      removeProject = true,
    } = {}) {
      try {
        const project = rootGetters['project/currentProject'];

        await logoutUser({
          token: rootGetters.token({ projectId: project.id }),
          url: project.url_api_ios,
        });
        commit('updateUser', { user: null });
        dispatch('deleteToken', { projectId: project.id }, { root: true });
        if (removeProject) {
          dispatch('project/removeProject', { projectId: project.id }, { root: true });
        }
      } catch (e) {
        console.error(e);
      }
    },

    saveUser({ commit }, { user }) {
      commit('updateUser', { user });
    },
  },
};
