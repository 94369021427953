<template>
  <div>
    <v-form v-model="valid" ref="form">
      <v-container>
        <v-layout>
          <v-flex xs12 sm4 offset-sm4>
            <v-card>
              <v-img
                class="white--text align-end"
                src="https://cdn.vuetifyjs.com/images/cards/desert.jpg"
                aspect-ratio="2.75"
              >
                <v-card-title>{{ $t('login.formTitle') }}</v-card-title>
              </v-img>

              <v-card-subtitle class="pb-0">
                {{ $t('login.formSubtitle') }}
              </v-card-subtitle>
              <v-card-text>
                <div :class="$style.formWrapper">
                  <v-text-field
                    v-model="login"
                    :rules="[rules.required]"
                    :label="$t('login.login')"
                    required
                    :class="$style.field"
                  >
                  </v-text-field>
                  <v-text-field
                    v-model="password"
                    :rules="[rules.required]"
                    type="password"
                    :label="$t('login.password')"
                    required
                    :class="$style.field"
                  >
                  </v-text-field>
                </div>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  color="primary"
                  @click="submit"
                >
                  {{ $t('login.submit') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import generateToken from '@/utils/generateToken';
import { login } from '../actions';

export default {
  name: 'LoginView',

  data() {
    return {
      login: '',
      password: '',
      valid: false,
      rules: {
        required: (value) => !!value || this.$t('login.fieldRequired'),
      },
    };
  },

  computed: {
    ...mapGetters('project', [
      'getProject',
    ]),

    projectId() {
      return parseInt(this.$route.params.id, 10);
    },

    project() {
      return this.getProject({ projectId: this.projectId });
    },
  },

  methods: {
    ...mapActions([
      'saveToken',
    ]),

    ...mapActions('user', [
      'saveUser',
    ]),

    ...mapActions('project', [
      'selectProject',
    ]),

    async submit() {
      const formValid = this.$refs.form.validate();

      if (!formValid) return;

      const token = generateToken();
      const payload = {
        token,
        login: this.login,
        password: this.password,
      };

      try {
        const { data: { DETAIL_USER: user } } = await login({
          payload,
          url: this.project.url_api_ios,
        });

        if (Array.isArray(user)) {
          this.$root.$global.show({
            message: this.$t('login.wrong_credentials'),
            color: 'red',
            icon: 'error',
          });
          return;
        }

        if (user) {
          await this.saveToken({ token, projectId: this.projectId });
          await this.saveUser({ user });
          this.selectProject({ project: this.project });

          if (this.$route.params.redirectURL) {
            this.$router.push({ path: this.$route.params.redirectURL });
          } else {
            this.$router.push({ name: 'about' });
          }
        }
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>

<style lang="less" module>
.formWrapper {
  width: 100%;
}
</style>
