<template>
  <v-app>
    <GlobalAlert ref="globalAlert" />
    <ProjectHeader />
    <SidebarMenu />
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import GlobalAlert from './components/GlobalAlert/GlobalAlert.vue';
import ProjectHeader from './components/Header/ProjectHeader.vue';
import SidebarMenu from './components/SidebarMenu/SidebarMenu.vue';

export default {
  name: 'App',

  data() {
    return {
      defaultBackground: '',
    };
  },

  components: {
    GlobalAlert,
    ProjectHeader,
    SidebarMenu,
  },

  computed: {
    ...mapGetters('project', [
      'background',
    ]),
  },

  watch: {
    '$route.fullPath': function () {
      this.setBackgrounds();
    },
  },

  created() {
    this.setMobile({
      isMobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent),
    });
    this.fetchProjects();
  },

  mounted() {
    this.defaultBackground = window.getComputedStyle(document.querySelector('#app')).getPropertyValue('background-color');
    this.$root.$global = this.$refs.globalAlert;
    this.setBackgrounds();
  },

  methods: {
    ...mapActions([
      'setMobile',
    ]),

    ...mapActions('project', [
      'fetchProjects',
    ]),

    async setBackgrounds() {
      if (this.$route.name === 'home' || !this.background) {
        document.querySelector('#app').style.background = this.defaultBackground;
      } else {
        document.querySelector('#app').style.background = `linear-gradient( rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.5) ), url(${this.background}) 0% 0% / cover`;
      }
    },
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: 'Railway';
  src: local('Railway'),
   url(./fonts/Raleway-VariableFont_wght.ttf) format('truetype');
}

.v-application {
  font-family: Railway, serif;
}
</style>
