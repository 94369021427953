export default {
  namespaced: true,

  state: {
    toggled: false,
  },

  getters: {
    toggled: (state) => state.toggled,
  },

  mutations: {
    toggle(state, { toggled }) {
      state.toggled = toggled !== undefined ? toggled : !state.toggled;
    },
  },

  actions: {
    toggle({ commit }, { toggled }) {
      commit('toggle', { toggled });
    },
  },
};
