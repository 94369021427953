<template>
  <div>
    <h1 :class="$style.title">{{ $t('projects.all_projects') }}</h1>
    <ProjectList />
  </div>
</template>

<script>
import ProjectList from '@/components/ProjectList/ProjectList.vue';

export default {
  name: 'HomeView',

  data() {
    return {
      tab: 0,
    };
  },

  components: {
    ProjectList,
  },
};
</script>

<style lang="less" module>
.title {
  margin-left: 48px;
  margin-top: 20px;
}
</style>
