<template>
  <v-btn
    :loading="loading"
    @click="onButtonClick"
    :color="buttonColor"
  >
    {{ buttonText }}
  </v-btn>
</template>

<script>
import { buttonStatusCheck, runCommand } from '@/actions';
import { mapGetters } from 'vuex';

export default {
  name: 'CommandButton',

  props: {
    item: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      interval: null,
      text: '',
      color: '',
      disabled: false,
    };
  },

  computed: {
    ...mapGetters([
      'token',
    ]),

    ...mapGetters('project', [
      'currentProject',
    ]),

    buttonText() {
      return this.text || this.item.descr;
    },

    buttonColor() {
      return this.color || (this.loading ? 'green ' : 'blue-grey darken-1');
    },
  },

  mounted() {
    this.getButtonStatus();
  },

  beforeDestroy() {
    clearTimeout(this.interval);
  },

  methods: {
    async onButtonClick() {
      if (this.disabled) {
        return;
      }

      this.loading = true;

      await runCommand({
        token: this.token({ projectId: this.currentProject.id }),
        commandName: this.item.p_cmd_in,
        url: this.currentProject.url_api_ios,
      });

      await this.getButtonStatus(false);
      this.loading = false;
    },

    async getButtonStatus(recursive = true) {
      const { data: { cmd_result: result } } = await buttonStatusCheck({
        token: this.token({ projectId: this.currentProject.id }),
        commandName: this.item.p_cmd_in,
        url: this.currentProject.url_api_ios,
      });
      const [commandResult] = result;

      if (commandResult.status) {
        const parsed = JSON.parse(commandResult.status);
        this.text = parsed.text;
        this.color = parsed.color;
        this.disabled = !!parsed.blocked;
      } else {
        this.text = '';
        this.color = '';
        this.disabled = false;
      }

      if (!recursive) {
        return;
      }

      this.interval = setTimeout(this.getButtonStatus, 3000);
    },
  },
};
</script>
