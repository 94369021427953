import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import i18n from './i18n';

Vue.config.productionTip = false;

// if (store.getters.isAuthenticated) {
//   store.dispatch('user/authentificate');
// }
Vue.prototype.$subscribe = function (mutationName, callback) {
  return this.$store.subscribe((mutation, state) => {
    if (mutation.type === mutationName) {
      callback(mutation.payload, state);
    }
  });
};

Vue.prototype.$subscribeAction = function (actionName, callback) {
  return this.$store.subscribeAction((action, state) => {
    if (action.type === actionName) {
      callback(action.payload, state);
    }
  });
};

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
